import React from 'react'
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import maps from "../../Assets/Image/contact_info.png";
import Fade from 'react-reveal/Fade';
import { TravelExploreRounded } from '@mui/icons-material';

const ContactUs = () => {
    return (
        <div>
            <div className='contact_us_sec'>

                <section className="contactinfo-sec" id='header-banner'>
                    <div className='__custom_container'>
                        <Row>
                            <Col lg={12}>
                                <div className="contact-content">
                                    <Fade top>
                                        <h1>Contact Us</h1>
                                        <div className="home-cont">
                                            <p>For inquiries, please reach out via email or phone. Our support team is available 24/7 to assist you. We look forward to hearing from you!</p>
                                        </div>
                                    </Fade>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <Fade bottom>
                    <section className="cards-sec">
                        <div className='__custom_container'>
                            <div class="Product_sec">
                                <div class="my_container">
                                    <div class="inner_content">
                                        <div class="row row_add">
                                            <div class="col-lg-12 p-0">
                                                <div class="info_sec">
                                                    <div class="cards_inner">
                                                        <div class="card">
                                                            <LocationOnIcon />
                                                            <h2>Location Info.</h2>
                                                            <p>25 High Street, Prestbury, Cheltenham, United Kingdom, GL52 3AR</p>
                                                        </div>
                                                        {/* <div class="card">
                                                            <PhoneIcon />
                                                            <h2>Contact Info.</h2>
                                                            <p>+91 9888888888</p>
                                                            <p>+91 9888888888</p>
                                                        </div> */}
                                                        <div class="card">
                                                            <MailIcon />
                                                            <h2>Mail Info.</h2>
                                                            <p>support@vistafinance.io</p>
                                                            <p>info@vistafinance.io</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Fade>
                <Fade left>
                    <section className="map-sec">
                        <div className='__custom_container'>
                            <Row>
                                <Col lg={4} className="map-col">
                                    <div className="map-details">
                                        <img src={maps} alt="maps" />
                                    </div>
                                </Col>
                                <Col lg={8} className="map-col">
                                    <div className="cont-form">
                                        <h4>Send a message</h4>
                                        <form>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="form-grps">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter you name"
                                                            className="form-txt"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="form-grps">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter you email"
                                                            className="form-txt"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="form-grps">
                                                        <input
                                                            type="number"
                                                            placeholder="Mobile no"
                                                            className="form-txt"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="form-grps">
                                                        <input
                                                            type="text"
                                                            placeholder="Company"
                                                            className="form-txt"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="form-grps">
                                                        <textarea
                                                            placeholder="Enter you message"
                                                            className="form-txt"
                                                        ></textarea>
                                                    </div>
                                                </Col>
                                                <button type="submit" className="sendmess">
                                                    Send Message
                                                </button>
                                            </Row>
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </Fade>
            </div>

        </div>
    )
}

export default ContactUs
