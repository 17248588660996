import React from 'react';

const BlockchainData = () => {
  return (
    <>
    <div className='__blockchain_keylabs'>
        keylabs
    </div>
    </>
  );
}

export default BlockchainData;
