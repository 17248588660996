import React from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import __Img_banner from '../../Assets/Image/banner_bg.png';
import __Img_blockchain from '../../Assets/Image/img_doller_drop.png';
import __img_MetaMask from '../../Assets/Image/MetaMask.svg';
import __Img_fees from '../../Assets/Image/blockchain_img_new.png';
import { title } from '../../config/Config';
import { AdbRounded, Launch, LocalLibraryRounded, SettingsInputAntennaRounded, TravelExploreRounded } from '@mui/icons-material';
import MachineLearning from './MachineLearning';
import Fade from 'react-reveal/Fade';

const Index = () => {


  // const [scrollPosition, setScrollPosition] = useState(0);
  // const [section, setSection] = useState(0);
  // const [stopAnimation, setStopAnimation] = useState(false);

  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollPosition(position);

  //   // Determine the section based on scroll position
  //   const firstSection = document.getElementById('header-banner');
  //   const secondSection = document.getElementById('second-section');

  //   const firstSectionHeight = firstSection ? firstSection.offsetHeight : 0;
  //   const secondSectionHeight = secondSection ? secondSection.offsetHeight : 0;

  //   if (position < firstSectionHeight) {
  //     setSection(1);
  //   } else if (position < firstSectionHeight + secondSectionHeight) {
  //     setSection(2);
  //   } else {
  //     setSection(3);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // // Compute styles based on the current section
  // let transformStyle = 'none';
  // if (!stopAnimation) {
  //   if (section === 1) {
  //     transformStyle = `translateX(${scrollPosition * 1.5}px)`;
  //   } else if (section === 2) {
  //     transformStyle = `translateX(${scrollPosition * -1.5}px)`;
  //   } else if (section === 3) {
  //     transformStyle = `translateY(${scrollPosition * -1.5}px)`;
  //   }
  // }



  return (
    <>

      {/* <div
      className={`__animation ${stopAnimation ? 'stop-animation' : ''}`}
      style={{ transform: transformStyle }}
    >
      <img src={shortLogo} alt={`${title} Logo`} />
    </div> */}

      <section className='__vf_banner' id='header-banner'>
        <div className='__custom_container'>
          <Row>
            <Col xl={6}>
              <div className='__bannerLeft'>
                <Fade bottom>
                  <h2><span>{title}</span> is one such crypto platform, which is the best platform to secure</h2>
                </Fade>
                <Fade bottom>
                  <p>which is the best platform to secure the
                    transactions between the two parties to prevent any kind of problem between
                    the merchant and consumer parties which are private management sectors in
                    which blockchain technology and are based on smart contracts. We bring
                    trade, brokers, traders, and investors together in a decentralized, open, and
                    fair network to make them more modern and global. This backstage smart
                    contract technology provides an automated and completely transparent
                    method of investing and a profit- sharing system by offering investment
                    attractiveness to successful traders around the world.</p>
                </Fade>
                <div className='__moreLink'>
                  <Link to="/contact-us" className="btn MoreLink"><LocalLibraryRounded /> Join community </Link>
                </div>
              </div>
            </Col>

            <Col xl={6} className='__bannerRightcoumn'>
              <div className='__bannerRight'>

                <img src={__Img_banner} alt='banner' />

              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className='__infoBanner' id='second-section'>
        <div className='__custom_container'>
          <Row>

            <Col sm={12}>
              <div className='__infoBannerHeader'>
                <h4>the Vista finance Gateway</h4>
              </div>
            </Col>

            <Col xl={4} md={6}>
              <Fade left>
                <div className='____infoCard'>
                  <div className='__iinfoHeader'>
                    <p>Vista finance Gateway</p>
                  </div>
                  <div className='data'>
                    the governing VISTA Token is a solution that is conceptualized between consumers
                    and merchants for any kind of blockchain from the traditional card payment
                    industry to smart contracts like Binance Smart Chain. VISTA blockchain will be
                    the core blockchain for VISTA and VISTA Token, Optimized for real-time VISTA
                    transactions created for Payment Service Providers (PSPs) and EFTPOS devices,
                    web3.0 enables payments in standard terminal messages with full support for
                    backward-compatible financial protocols such as ISO8583.
                  </div>
                </div>
              </Fade>
            </Col>


            <Col xl={4} md={6}>
              <Fade bottom>
                <div className='____infoCard'>

                  <div className='__iinfoHeader'>
                    <p>exchanges, brokers, traders, and investors</p>
                  </div>

                  <div className='data'>
                    We unite exchanges, brokers, traders, and investors in a decentralized, open,
                    and fair network, making the financial market even more global. This will allow
                    successful traders to rapidly scale up their trading strategies by attracting
                    investments from all over the world and benefiting investors and letting them
                    invest around the world.
                  </div>
                </div>
              </Fade>
            </Col>

            <Col xl={4} md={6}>
              <Fade right>
                <div className='____infoCard'>
                  <div className='__iinfoHeader'>
                    <p>critical industry problems</p>
                  </div>

                  <div className='data'>
                    VISTA is the best platform to solve critical industry problems like lack of
                    information, lack of transparency, and most of all, lack trust because investors
                    are not aware of it and they hesitate to invest money. The raising and use of
                    VISTA (tokens) will help fund product development, marketing, and advertising
                    for the development of the platform. The VISTA token is an internal currency of
                    the VISTA platform and will be used for all investment functions and profit
                    distribution to investors.
                  </div>
                </div>
              </Fade>
            </Col>
          </Row>
        </div>
      </section>


      <section className='__blockchain' id="third-section">
        <div className='__blockchainCard'>
          <Row>
            <Col lg={6}>
              <div className='__b-left'>
                <Fade left>
                  <h3> What is Blockchain and how does it works?</h3>

                  <p> Just as the Internet was invented by connecting thousands and millions of
                    computers, in the same way a long chain of data blocks (data) has been named
                    Blockchain. Blockchain technology is a combination of three different
                    technologies, which include the Internet, personal 'key' cryptography (private
                    key) i.e. keeping information secret and controlling protocols.</p>
                  <Link to="/intro" className='__b-link btn'>  <AdbRounded /> read the report </Link>
                </Fade>
              </div>
            </Col>
            <Col lg={6}>
              <div className='__b-right'>

                <img src={__Img_blockchain} alt='blockchain-icon' />
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <MachineLearning />

      <section className='fees__section'>
        <div className='__custom_container'>
          <Row>
            <Col sm={12}>
              <div className='__feesHeader'>
                <p> will in this manner lose a limited quantity of VISTA.</p>
                <h3>Fees for VISTA , Blockchain and  Token</h3>
              </div>
            </Col>

            <Col lg={6}>
              <Fade left>
                <div className='__feesImg'>
                  <img src={__Img_fees} alt="fees_banner" />
                </div>
              </Fade>
            </Col>

            <Col lg={6}>
              <Fade right>
                <div className='__feesLeft'>
                  <h5>There is an exchange expense for all exchanges passing through the VISTA door.
                    This expense is paid by the customer for using the shipper security. The expense
                    is as of now 20% of the exchange charge on the blockchain. The expense goes to
                    the charge pool.</h5>

                  <p>•  There is a question expense of 3 VISTA, for questioning an exchange. This
                    expense will be disseminated to the charge pool for ADM. For Questioned
                    cases the accompanying dissemination:</p>

                  <p>•  The escrow isn't token explicit (the agreement doesn't accept a particular BEP20
                    token) so a solitary record may escrow various tokens simultaneously. For each
                    (account, token) pair, the agreement tracks its equilibrium and when escrow
                    lapses.</p>

                  <p>• 25% of the expense is moved to the main question specialist </p>
                  <p> • 25% of the expense is moved to the second assessment specialist </p>
                  <p> • 45% of the expense is moved to the charge poo</p>
                  <p>•  5% will be scorched for flattening</p>

                </div>
              </Fade>
            </Col>
          </Row>
        </div>
      </section>

      <div className='__proBlockchain'>
        <div className='__custom_container'>

          <Row className='for_space_media_'>
            <Col sm={12}>
              <div className='__topHeader'>
                <h4> VISTA Blockchain</h4>
                <p>US trades like NYSE, CME and CBOE can deal with countless exchanges each
                  second and have a coordinating with dormancy in the microseconds. This
                  speed is fundamentally quicker than current blockchains. Specifically,
                  Binance can deal with 15 exchanges for every second4, has a between block
                  season of 15 seconds5 and exchanges frequently cost more than $1 each.
                  VISTA Convention is worked for the Binance blockchain as brilliant
                  agreements for bigger exchanges where the Binance exchange cost is a
                  negligible portion of the item cost and is equal to the cost for all-size
                  exchanges.</p>
              </div>
            </Col>
            <Col md={6}>
              <Fade left>
                <div className='__blockchainVB'>

                  <div className='Icon'>
                    <SettingsInputAntennaRounded />
                  </div>
                  <p>A broadly took on, totally on-chain VISTA Installment Convention would have to
                    have practically identical exchange throughput from a basic blockchain to scale.
                    VISTA convention is worked for Binance. With this limit, it would hypothetically
                    have the option to help the movement on Visa, Expert card, and all the USbased trades consolidated. The principle blockchain for VISTA Convention is the
                    VISTA Blockchain and will permit designers to convey Individual Tokens, Credit
                    Tokens (DeFi, decentralized money tokens), Hierarchical Tokens, NFT Tokens,
                    Crowd funding Tokens (DeFi) and daps with close to constant exchange speeds.</p>

                  <p>• 25% of the exchange expense is singed to guarantee that VISTA Token
                    is deflationary.</p>
                </div>
              </Fade>
            </Col>

            <Col md={6}>
              <Fade right>
                <div className='__blockchainVB'>

                  <div className='Icon'>
                    <TravelExploreRounded />
                  </div>

                  <p>VISTA Blockchain is a private blockchain that is interchain connected with VISTA
                    Conventions (keen agreements) on open blockchains like Binance by means
                    of decentralized prophets. VISTA Blockchain is intended for vendor exchanges in
                    any case in case they are portable, instore, or web-based businesses and uses
                    the trade idea from the installment card industry where the dap/token is the
                    "card backer" that gets the significant piece of the exchange charge.</p>
                  <p>• 50% of the exchange charge is a trade and goes to the dap /token and
                    canbe used by the agreement or the agreement proprietor.</p>

                  <p>• 25% of the exchange expense goes to the square maker.</p>
                </div>
              </Fade>
            </Col>
          </Row>


        </div>
      </div>

      <section className='__tokenar'>
        <div className='__custom_container'>
          <Row>
            <Col sm={12}>
              <div className='__tokenHeader'>
                <h4>VISTA Token</h4>
                <p> The blockchain-based VISTA environment will have its own money – VISTA
                  Token. The utility and the use of the VISTA Token compare to the conveniences
                  of blockchain innovation and tokenization. VISTA Token will be intended to be a
                  utility Token and work with local area administration and boost the upright circle
                  of VISTA Environment and assume control over the BEP20 token VISTA Token.
                  The BEP20 VISTA Token will be 1:1 convertible to VISTA Token when the VISTA
                  Blockchain's Main net opens. VISTA Token is additionally the primary cash
                  on the VISTA Blockchain and all charges will be paid in VISTA Token.</p>
              </div>
            </Col>

            <Col sm={12}>
              <div className='__tokenSpace'>
                <h5> </h5>


              </div>
            </Col>

            <Col sm={12}>
              <Accordion defaultActiveKey=".0">
                <Fade left>
                  <Accordion.Item eventKey=".0">
                    <Accordion.Header>Token information</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          <p> Token Name</p>
                          <p> vista finance</p>
                        </li>

                        <li>
                          <p> Token Ticker</p>
                          <p> vista</p>
                        </li>

                        <li>
                          <p> Token type</p>
                          <p> BEP-20</p>
                        </li>

                        <li>
                          <p> Token Supply</p>
                          <p> 21000000</p>
                        </li>

                        <li>
                          <p> Token Contract</p>
                          <p> <Link to="https://bscscan.com/token/0x493361d6164093936c86dcb35ad03b4c0d032076" target='_blank'> Contract Address <Launch /></Link> </p>
                        </li>

                        <li>
                          <p> Token Website</p>
                          <p> www.vistafinance.org <Link to="http://vistafinance.org/" target='_blank'> <Launch /></Link></p>
                        </li>

                        <li>
                          <p> Token founder and initial governance</p>
                          <p> Vista Foundation</p>
                        </li>

                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Fade>
                <Fade left>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>VISTA Token liquidity building mechanisms:</Accordion.Header>
                    <Accordion.Body>
                      <p>The biological systems primary cash for exchange expenses VISTA Token</p>
                      <p> Crypto installments are still limitlessly unregulated. However, it will, as we
                        would like to think, be guileless to accept that post-mass reception, the climate
                        will stay static. All things considered, nearby controllers will put web3.0
                        installments in one of the installment mandates, similar to the Electronic Asset
                        Move Act in the USA or Installment Administration Order in the EU (or
                        comparative) for shopper rights/assurance. Enactments will, after some time,
                        help to push web3.0 drives that will compel traders to remain agreeable with
                        new current and new enactments.</p>

                      <p>Decentralized administration requires even impetus systems that precisely model
                        both positive and adverse results. All in all, the overseeing substances ought to be
                        remunerated for acceptable outcomes and punished for terrible ones. The VISTA
                        Token is intended to work with this through three fundamental utilities.
                        Exchanging: Token holders are boosted to assist with getting mass reception of
                        the convention, which will prompt a higher worth of the token.</p>

                      <p><b>Note:</b> that it is basic for these three utilities to match. All overseeing substances
                        should get awards for them to administer in a manner that amplifies income.
                        All administering substances should teach the market to guarantee mass
                        reception to expand the worth of the token. To this end, VISTA Token will have
                        a solitary charge pool.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Fade>
                <Fade left>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header> VISTA Utilities</Accordion.Header>
                    <Accordion.Body>
                      <p>VISTA token holdings is a requirement to be a part of the work pool.</p>
                      <p> Long-term VISTA token holders will accumulate more voting power for governance.</p>

                      <h5>Governance</h5>

                      <p> Once experienced, VISTA will step by step progress the VISTA Convention and the
                        VISTA Blockchain to local area administration, permitting the local area to choose
                        the fate of the convention. VISTA token holders might stake their VISTA Token to
                        decide on or propose novel plans to further develop VISTA Convention. Some of
                        such choices could be:</p>

                      <p> Addition/removal of tokens accepted on Vista Finance
                        Protocol parameters such as collateral factor, reputation algorithms, supply cap, risk
                        limits. Merchant reputation voting.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Fade>
                <Fade left>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header> Token Burn</Accordion.Header>
                    <Accordion.Body>
                      <p>Burning events will be held publicly every year. Public participation is anticipated.</p>
                      <p> The BEP20 Token</p>

                      <p>  Vista Token is Vista Finance's () native protocol token, currently issued on Binance
                        following the BEP-20 standard.</p>
                      <p> The Vista token is a utility token designed to facilitate community governance and
                        incentivizes the virtuous circle of the Vista Ecosystem. </p>
                      <p> The BEP20 Vista Token will be 1:1 convertible to Vista Coin which is the on- block native
                        currency on the Vista Blockchain. </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Fade>
                <Fade left>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header> Smart Contracts</Accordion.Header>
                    <Accordion.Body>
                      <p>We will send VISTA and virtual resources as BEP-20 tokens on the Binance
                        organization. Binance is the most well-known and broadly upheld keen
                        agreement blockchain, with an immense improvement local area and strong language support. We trust it has a solid future.</p>

                    </Accordion.Body>
                  </Accordion.Item>
                </Fade>
                <Fade left>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header> Public API</Accordion.Header>
                    <Accordion.Body>
                      <p>VISTA will have a public Stage Programming interface utilizing the JSON-RPC
                        convention with techniques for financial records balances and getting to every
                        one of the above keen agreements. This will permit sites to show information
                        to web clients without requiring a web3 wallet augmentation introduced in
                        the internet browser.</p>

                    </Accordion.Body>
                  </Accordion.Item>
                </Fade>
                <Fade left>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header> The VISTA "Smart" Wallet</Accordion.Header>
                    <Accordion.Body>
                      <p>A vital part in making all that simple to utilize is the VISTA Wallet. Expanding on
                        existing light wallet plan, the VISTA Wallet smoothed out the client experience
                        further by coordinating with games and sites that the client trusts. Client
                        accounts on each believed stage will be synchronized to the client's Binance
                        address. We consider this a "savvy" wallet as a result of different bits of
                        usefulness that cooperate to work on the client experience:</p>


                      <p>● The Stage Programming interface is utilized to connect confirmed gaming and
                        site accounts.</p>
                      <p>● Exchange Solicitations are sent from believed stages straightforwardly to the
                        wallet.</p>
                      <p>● Memberships are affirmed and overseen by every nearby wallet.</p>
                      <p>● Exchange cutoff points and limits authorized by the wallet's shrewd
                        agreement.</p>
                      <p>● Savvy Agreements are utilized behind the scenes for information and showed
                        in a clean UI</p>
                      <p>● Neighborhood rules can be set okay with tolerating and computerizing
                        Exchange Solicitations</p>

                    </Accordion.Body>
                  </Accordion.Item>
                </Fade>
              </Accordion>
            </Col>
          </Row>
        </div>
      </section>


      <div className='__app' id='meta'>
        <div className='__custom_container'>
          <Row>
            <Col lg={6}>
              <Fade left>
                <div className='__appLeft'>
                  <h3>Mobile App and Token Wallets</h3>
                  <p>One of the goals of the project is the development of the native mobile wallet
                    Called VISTA Wallet with the full usefulness of the wallet, trade, and Token
                    move. Notwithstanding, until it is accessible, VISTA Token can be put away in
                    all wallets supporting BEP-20 tokens. Here is a list of some wallets with
                    support of BEP20 tokens:</p>


                  <h5>Meta Mask Wallet PC and MAC (recommended and compatible with hardware wallets) </h5>

                  <Link to="https://metamask.io/" target='_blank' className='btn'> Learn about MetaMask <Launch /> </Link>

                </div>
              </Fade>
            </Col>

            <Col lg={6}>
              <Fade right>
                <div className='__appRyt'>
                  <img src={__img_MetaMask} alt="MetaMask-icon" />
                </div>
              </Fade>
            </Col>
          </Row>
        </div>
      </div>





    </>
  )
}

export default Index