import React from 'react'
import { logo, shortLogo, title } from '../../config/Config'
import { Link } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Col, Row } from "react-bootstrap";
import Fade from 'react-reveal/Fade';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import token_logo from "../../Assets/Image/token_logo.png"

const RoadMap = () => {
    return (
        <div>
            <div className='roadmap_page'>
                <section className="roadmap-sec" id='header-banner'>
                    <div className='__custom_container'>
                        <Row>
                            <Col lg={12}>
                                <div className="contact-content">
                                    <Fade top>
                                        <h1>Tokenomics Roadmap</h1>
                                        <div className="home-cont">
                                            <p>Our token roadmap outlines key milestones and development phases. It includes launch, platform enhancements, and community engagement. Stay tuned for updates on our progress!</p>
                                        </div>
                                    </Fade>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <div className="roadmap_sec">
                    <div className="__custom_container">
                        <div className="mission_sec">
                            <div className="my_container">
                                <div className="inner_sec">
                                    <div className="img">
                                        <img src={token_logo} alt="" />
                                    </div>
                                    <div className="content">
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='d-flex align-items-center justify-content-between w-100'>
                                                <h2>NAME</h2>
                                                <ArrowRightAltIcon />
                                            </div>
                                            <p className='w-100 text-end'>VISTA FINANCE</p>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='d-flex align-items-center justify-content-between w-100'>
                                                <h2>SYMBOL</h2>
                                                <ArrowRightAltIcon />
                                            </div>
                                            <p className='w-100 text-end'>VISTA</p>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='d-flex align-items-center justify-content-between w-100'>
                                                <h2>NETWORK</h2>
                                                <ArrowRightAltIcon />
                                            </div>
                                            <p className='w-100 text-end'>BEP20</p>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='d-flex align-items-center justify-content-between w-100'>
                                                <h2>DECIMALS</h2>
                                                <ArrowRightAltIcon />
                                            </div>
                                            <p className='w-100 text-end'>18</p>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='d-flex align-items-center justify-content-between w-100'>
                                                <h2>TOTAL SUPPLY</h2>
                                                <ArrowRightAltIcon />
                                            </div>
                                            <p className='w-100 text-end'>21 M</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-5 d-flex align-items-center justify-content-center gap-3'>
                                <Link className='btn_new' to='https://bscscan.com/token/0x493361d6164093936c86dcb35ad03b4c0d032076' target='_blank'>View Contract</Link>
                                <Link className='btn_new' to='https://coinmarketcap.com/currencies/koloop-basic/' target='_blank'>View CMC</Link>
                            </div>
                        </div>
                        <div className="inner_sec">
                            <div className=" h-100 w-100">
                                <main id="infographic" className="d-flex flex-column align-items-center py-5">

                                    <section className="step one for_color">
                                        <div className="circle">
                                            <img className="roadmap_img" src={token_logo} alt="" />
                                        </div>
                                        <article data-step="1">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_1' />
                                                <h6 className="text-uppercase my-3 ps-4"><span>1. </span> Project planing</h6>
                                            </header>

                                            <div className="body">
                                                <small> <span>2018</span> BIRTH OF COMMUNITY, consectetur adipiscing elit, sed do
                                                    eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                    consequat. </small>

                                                <div className="d-flex justify-content-between mt-3 controls">
                                                    <a className="btn btn-outline-danger invisible" href="#">Prev</a>
                                                    <a className="btn btn-outline-danger" href="#">Next</a>
                                                </div>
                                            </div>
                                        </article>
                                        <article data-step="2">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_2' />
                                                <h6 className="text-uppercase my-3 ps-4"><span>2. </span> Company Registration</h6>
                                            </header>

                                            <div className="body">
                                                <small> <span>2018</span> BIRTH OF COMMUNITY, consectetur adipiscing elit, sed do
                                                    eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                    consequat. </small>
                                                <div className="d-flex justify-content-between mt-3 controls">
                                                    <a className="btn btn-outline-danger" href="#">Prev</a>
                                                    <a className="btn btn-outline-danger" href="#">Next</a>
                                                </div>
                                            </div>
                                        </article>
                                        <article data-step="3">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_3' />
                                                <h6 className="text-uppercase my-3 ps-4"><span>3. </span> Token Deploy</h6>
                                            </header>

                                            <div className="body">
                                                <small> <span>2018</span> BIRTH OF COMMUNITY, consectetur adipiscing elit, sed do
                                                    eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                    consequat. </small>
                                                <div className="d-flex justify-content-between mt-3 controls">
                                                    <a className="btn btn-outline-danger" href="#">Prev</a>
                                                    <a className="btn btn-outline-danger" href="#">Next</a>
                                                </div>
                                            </div>
                                        </article>
                                        <article data-step="4">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_4' />
                                                <h6 className="text-uppercase my-3 ps-4"><span>4. </span> Cummunity build program</h6>
                                            </header>

                                            <div className="body">
                                                <small> <span>2018</span> BIRTH OF COMMUNITY, consectetur adipiscing elit, sed do
                                                    eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                    consequat. </small>
                                                <div className="d-flex justify-content-between mt-3 controls">
                                                    <a className="btn btn-outline-danger" href="#">Prev</a>
                                                    <a className="btn btn-outline-danger" href="#">Next</a>
                                                </div>
                                            </div>
                                        </article>
                                        <article data-step="5">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_5' />
                                                <h6 className="text-uppercase my-3 ps-4"><span>
                                                    5. </span> Token exhchange listing</h6>
                                            </header>
                                            <div className="body">
                                                <small> <span>2018</span> BIRTH OF COMMUNITY, consectetur adipiscing elit, sed do
                                                    eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                    consequat. </small>
                                                <div className="d-flex justify-content-between mt-3 controls">
                                                    <a className="btn btn-outline-danger" href="#">Prev</a>
                                                    <a className="btn btn-outline-danger" href="#">Next</a>
                                                </div>
                                            </div>
                                        </article>
                                    </section>

                                    <section className="step two for_color">
                                        <div className="circle">
                                            <img className="roadmap_img" src={token_logo} alt="" />
                                        </div>
                                        <article data-step="6">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_6' />
                                                <h6 className="text-uppercase my-3 pe-4"><span>6. </span> Vista bank lunch</h6>
                                            </header>
                                        </article>
                                        <article data-step="7">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_7' />
                                                <h6 className="text-uppercase my-3 pe-4"><span>7. </span> Vista nft marketplace</h6>
                                            </header>
                                        </article>
                                        <article data-step="8">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_8' />
                                                <h6 className="text-uppercase my-3 pe-4"><span>8. </span> Vista cloud game
                                                </h6>
                                            </header>
                                        </article>
                                        <article data-step="9">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_9' />
                                                <h6 className="text-uppercase my-3 pe-4"><span>9. </span> Vista ce. exchange</h6>
                                            </header>
                                        </article>
                                        <article data-step="10">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_10' />
                                                <h6 className="text-uppercase my-3 pe-4"><span>10. </span>Vista blockchain
                                                </h6>
                                            </header>
                                        </article>
                                    </section>

                                    <section className="step one mt-4 for_color">
                                        <div className="circle">
                                            <img className="roadmap_img" src={token_logo} alt="" />
                                        </div>
                                        <article data-step="1">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_1' />
                                                <h6 className="text-uppercase my-3 ps-4"><span>11. </span> Vista de. chatplace</h6>
                                            </header>

                                            <div className="body">
                                                <small> <span>2018</span> BIRTH OF COMMUNITY, consectetur adipiscing elit, sed do
                                                    eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                    consequat. </small>

                                                <div className="d-flex justify-content-between mt-3 controls">
                                                    <a className="btn btn-outline-danger invisible" href="#">Prev</a>
                                                    <a className="btn btn-outline-danger" href="#">Next</a>
                                                </div>
                                            </div>
                                        </article>
                                        <article data-step="2">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_2' />
                                                <h6 className="text-uppercase my-3 ps-4"><span>12. </span> Vista data cloud space
                                                </h6>
                                            </header>

                                            <div className="body">
                                                <small> <span>2018</span> BIRTH OF COMMUNITY, consectetur adipiscing elit, sed do
                                                    eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                    consequat. </small>
                                                <div className="d-flex justify-content-between mt-3 controls">
                                                    <a className="btn btn-outline-danger" href="#">Prev</a>
                                                    <a className="btn btn-outline-danger" href="#">Next</a>
                                                </div>
                                            </div>
                                        </article>
                                        <article data-step="3">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_3' />
                                                <h6 className="text-uppercase my-3 ps-4"><span>13. </span> Vista skill shop</h6>
                                            </header>

                                            <div className="body">
                                                <small> <span>2018</span> BIRTH OF COMMUNITY, consectetur adipiscing elit, sed do
                                                    eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                    consequat. </small>
                                                <div className="d-flex justify-content-between mt-3 controls">
                                                    <a className="btn btn-outline-danger" href="#">Prev</a>
                                                    <a className="btn btn-outline-danger" href="#">Next</a>
                                                </div>
                                            </div>
                                        </article>
                                        <article data-step="4">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_4' />
                                                <h6 className="text-uppercase my-3 ps-4"><span>14. </span> Decentralized hospitality</h6>
                                            </header>

                                            <div className="body">
                                                <small> <span>2018</span> BIRTH OF COMMUNITY, consectetur adipiscing elit, sed do
                                                    eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                    consequat. </small>
                                                <div className="d-flex justify-content-between mt-3 controls">
                                                    <a className="btn btn-outline-danger" href="#">Prev</a>
                                                    <a className="btn btn-outline-danger" href="#">Next</a>
                                                </div>
                                            </div>
                                        </article>
                                        <article data-step="5">
                                            <header className="d-flex align-items-center for_bg bg-opacity-10">
                                                <NavigateNextIcon className='foot_5' />
                                                <h6 className="text-uppercase my-3 ps-4"><span>
                                                    15. </span> coming soon</h6>
                                            </header>
                                            <div className="body">
                                                <small> <span>2018</span> BIRTH OF COMMUNITY, consectetur adipiscing elit, sed do
                                                    eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                    consequat. </small>
                                                <div className="d-flex justify-content-between mt-3 controls">
                                                    <a className="btn btn-outline-danger" href="#">Prev</a>
                                                    <a className="btn btn-outline-danger" href="#">Next</a>
                                                </div>
                                            </div>
                                        </article>
                                    </section>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoadMap
