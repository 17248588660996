import React from 'react';
import __Img_crypto from '../../Assets/Image/__crypto.png';
import __Img_hngs from '../../Assets/Image/crypto__img.png';
import market_Analysis from '../../Assets/Image/market_Analysis.png';
import { Col, Row } from 'react-bootstrap';
import { FormatQuote, OpenInNew } from '@mui/icons-material';
import Fade from 'react-reveal/Fade';

const Cryptobanking = () => {
    return (
        <div className='crypto___'>
            <div className='crypto__banner' >
                <div className="crypto-data">
                    <Fade bottom>
                        <div className='__img'>
                            <img src={__Img_crypto} alt="img" />
                        </div>

                        <h3>Crypto Banking</h3>
                        <p>Empowering Your Finances with Blockchain Technology Secure, Transparent, and Efficient Crypto Banking</p>

                        <div>
                            <a href='#key_point'> Key points  <OpenInNew /> </a>
                        </div>
                    </Fade>
                </div>
            </div>

            <div className='__sec__crypto' >
                <div className='__custom_container'>
                    <Row>
                        <Col sm={12}>
                            <div className='__sec_header' >
                                <h6> Crypto Banking</h6>
                                <h2>Crypto Banking Overview</h2>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className='__sec-img'>
                                <Fade left>
                                    <img src={__Img_hngs} alt='vision-img' />
                                </Fade>
                            </div>
                        </Col>

                        <Col md={6}>
                            <Fade right>
                                <div className='__sec-card'>
                                    <p>Crypto banking, also known as cryptocurrency banking or
                                        blockchain banking, refers to the integration of traditional
                                        banking services with cryptocurrencies and blockchain
                                        technology. It involves the use of digital currencies, such
                                        as Bitcoin or Ethereum, and decentralized systems to
                                        offer financial services typically provided by traditional
                                        banks. </p>

                                    <p>Crypto banking combines the advantages of
                                        cryptocurrencies, such as fast and secure transactions,
                                        with the functionality of traditional banking services. It
                                        allows users to store, transfer, and manage their digital
                                        assets in a secure and transparent manner. Some
                                        common features and services offered by crypto banks
                                        include</p>
                                </div>
                            </Fade>
                        </Col>


                    </Row>

                </div>
            </div>

            <div className='key_point' id="key_point">
                <div className='__custom_container'>
                    <Row>
                        <Col sm={12}>
                            <div className='__sec_header' >
                                <h6> Crypto Banking</h6>
                                <h2>Key points</h2>
                            </div>
                        </Col>

                        <Col xl={3} lg={4} md={6}>
                            <Fade left>
                                <div className='card_a'>
                                    <div className='__icon'>
                                        <FormatQuote />
                                    </div>
                                    <div className='__twxt'>
                                        <h4> Digital Wallets:</h4>
                                        <p>Crypto banks provide digital wallets that enable users to store and manage their
                                            cryptocurrencies securely. These wallets are usually accessed through mobile or web applications. </p>
                                    </div>

                                </div>
                            </Fade>
                        </Col>

                        <Col xl={3} lg={4} md={6}>
                            <Fade left>
                                <div className='card_a'>
                                    <div className='__icon'>
                                        <FormatQuote />
                                    </div>

                                    <div className='__twxt'>
                                        <h4> Fiat-to-Crypto and Crypto-to-Fiat Conversion:</h4>
                                        <p>Crypto banks facilitate the conversion of traditional
                                            fiat currencies (e.g., US dollars, euros) into cryptocurrencies and vice versa. This allows users to
                                            easily switch between traditional and digital assets.</p>
                                    </div>

                                </div>
                            </Fade>
                        </Col>


                        <Col xl={3} lg={4} md={6}>
                            <Fade right>
                                <div className='card_a'>
                                    <div className='__icon'>
                                        <FormatQuote />
                                    </div>

                                    <div className='__twxt'>
                                        <h4>Payment Processing:</h4>
                                        <p>  Crypto banks enable users to make payments and transfers using
                                            cryptocurrencies. They provide payment gateways and merchant services to facilitate
                                            crypto-based transactions.</p>
                                    </div>

                                </div>
                            </Fade>
                        </Col>

                        <Col xl={3} lg={4} md={6} >
                            <Fade right>
                                <div className='card_a'>
                                    <div className='__icon'>
                                        <FormatQuote />
                                    </div>

                                    <div className='__twxt'>
                                        <h4>Loans and Interest-Earning Accounts:</h4>
                                        <p>Some crypto banks offer lending services where users
                                            can borrow against their cryptocurrency holdings. Additionally, they may provide interestearning accounts where users can earn interest on their digital assets.</p>
                                    </div>
                                </div>
                            </Fade>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className='__marketPage'>
                <div className='__custom_container'>
                    <Row>
                        <Col sm={12}>
                            <div className='__sec_header' >
                                <h6> Market Analysis</h6>
                                <h2>Cryptocurrency Banking Market Analysis and Size</h2>
                            </div>
                        </Col>

                        <Col md={6}>
                            <Fade bottom>
                                <div className='crypto__img__txt'>
                                    <p>The growing number of cryptocurrency users has a significant impact on the global
                                        cryptocurrency market. According to crypto.com, the total number of cryptocurrency
                                        users worldwide will reach 106 million in January 2021. (A supplier of crypto
                                        exchanges). The increased number of bitcoin exchange service providers, the
                                        adoption of DeFi, and the increasing acceptance of cryptocurrencies by financial
                                        institutions around the world are all factors contributing to the increase in
                                        cryptocurrency users. </p>

                                    <p>Data Bridge Market Research analyses that the cryptocurrency banking market was
                                        valued at USD 1.49 billion in 2021 and is expected to reach the value of USD 2.52
                                        billion by 2029, at a CAGR of 6.80% during the forecast period. In addition to the
                                        market insights such as market value, growth rate, market segments, geographical
                                        coverage, market players, and market scenario he market report curated by the Data
                                        Bridge Market Research team includes in-depth expert analysis, import/export
                                        analysis, pricing analysis, production consumption analysis, and pestle analysis.</p>
                                </div>
                            </Fade>
                        </Col>


                        <Col md={6}>
                            <div className='crypto__img'>
                                <Fade bottom>
                                    <img src={market_Analysis} alt='market_Analysis_img' />
                                </Fade>
                            </div>
                        </Col>

                    </Row>
                </div>
            </div>

            <div className='service__hng'>
                <div className='sec___custom_container'>
                    <Row>
                        <Col sm={12}>
                            <div className='__sec_header'>
                                <p> services</p>
                                <h1>Our Banking services</h1>
                            </div>
                        </Col>

                        <Col className='col-6'>
                            <div className='serviceCard'>
                                <p>01</p>
                                <h3>Decentralized wallet</h3>
                            </div>
                        </Col>

                        <Col className='col-6'>
                            <div className='serviceCard'>
                                <p>02</p>
                                <h3>Swap & converter</h3>
                            </div>
                        </Col>

                        <Col className='col-6'>
                            <div className='serviceCard'>
                                <p>03</p>
                                <h3>Asset on interest</h3>
                            </div>
                        </Col>

                        <Col className='col-6'>
                            <div className='serviceCard'>
                                <p>04</p>
                                <h3>Loan on asset</h3>
                            </div>
                        </Col>

                        <Col className='col-6'>
                            <div className='serviceCard'>
                                <p>05</p>
                                <h3>Buy/Sell on fiat currency</h3>
                            </div>
                        </Col>

                        <Col className='col-6'>
                            <div className='serviceCard'>
                                <p>06</p>
                                <h3>Buy/sell in P2P</h3>
                            </div>
                        </Col>

                        <Col className='col-6'>
                            <div className='serviceCard'>
                                <p>07</p>
                                <h3>Financial services</h3>
                            </div>
                        </Col>

                        <Col className='col-6'>
                            <div className='serviceCard'>
                                <p>08</p>
                                <h3>Decentralized exchange</h3>
                            </div>
                        </Col>

                    </Row>
                </div>
            </div>







        </div>
    );
}

export default Cryptobanking;
