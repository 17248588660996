import React from 'react';
import { Fade } from 'react-awesome-reveal';
import __img_banner from '../../Assets/Image/app-banner.png';
import __img_apps from '../../Assets/Image/wallet_bg.png';
import __img_Freedom from '../../Assets/Image/Freedom.png';
import __img_Management from '../../Assets/Image/Management.png';
import __img_Rewards from '../../Assets/Image/Rewards.png';
import __img_one_wallet from '../../Assets/Image/one_wallet.png';
import __img_Decentralized from '../../Assets/Image/Decentralized.png';
import __img_Blockchain from '../../Assets/Image/Blockchain.png';
import { Link } from 'react-router-dom';
import { CardGiftcard, Diversity3, Security, SupportAgent } from '@mui/icons-material';


const Project = () => {
    return (
        <>
            <div className='apps__'>
                <div className='team__banner' id='header-banner' >
                    <div className="team-data">
                        <Fade bottom>
                            <div className='__img'>
                                <img src={__img_banner} alt="img" />
                            </div>
                            <h3>Your Gateway to Decentralized Financial Freedom</h3>
                            <p>Manage your assets earn rewards and transact securely on the blockchain</p>

                            <p className='mt-2'>In a world where traditional financial systems can be cumbersome and opaque, Vista Finance emerges as a beacon of financial innovation. Our decentralized banking app redefines the way you manage your finances, offering you a seamless and secure gateway to the exciting world of decentralized finance (DeFi).</p>
                        </Fade>
                        <div>
                            {/* <a href='#ourView'> Our Vision  <OpenInNew /> </a> */}
                        </div>
                    </div>
                </div>

                <div className='__app-download'>
                    <div className='__custom_container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='heading_sec'>
                                    <h1> Wallet Apps</h1>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="left-section">
                                    <h2>Manage your assets earn rewards and transact securely on the blockchain</h2>
                                    <p>Built on a secure and transparent blockchain infrastructure, Vista Finance ensures that your financial activities are tamper-proof and verifiable. Your transactions are executed with the highest level of security and transparency, keeping your assets safe from potential threats.</p>
                                    <p> Keep track of your expenses, manage your budget, and ensure your financial health with our easy-to-use wallet app. Download now to get started. </p>

                                    <div className="buttons">
                                        <Link to="https://play.google.com/store/apps/details?id=com.vistafinance&pcampaignid=web_share" target="_blank" className="button">Vista Safe </Link>
                                        <Link to="https://play.google.com/store/apps/details?id=com.vistawallet.user&pcampaignid=web_share" target="_blank" className="button">Vista Wallet</Link>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className="right-section">
                                    <img src={__img_apps} alt="Download Wallet App" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='intro'>
                    <div className='__custom_container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='heading_sec'>
                                    <h1>Introducing Vista Finance: Your Gateway to Decentralized Financial Freedom</h1>
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className="intro-right">
                                    <img src={__img_Freedom} alt="Financial Freedom" />
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className="intro-left">
                                    <h2>Unshackling Financial Freedom</h2>
                                    <p>Imagine a world where you have complete control over your assets, free from the constraints of centralized institutions. Vista Finance empowers you with this very freedom. Say goodbye to traditional banks' restrictive policies and hello to a new era of financial sovereignty.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='intro intro-second '>
                    <div className='__custom_container'>
                        <div className='row'>

                            <div className='col-lg-6'>
                                <div className="intro-left">
                                    <h2>Seamless Asset Management</h2>
                                    <p>Vista Finance offers an intuitive platform that simplifies asset management. With just a few taps, you can securely store, send, receive, and trade a wide array of cryptocurrencies and tokens. Our user-friendly interface ensures that both beginners and seasoned crypto enthusiasts can navigate the world of decentralized finance effortlessly.</p>
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className="intro-right">
                                    <img src={__img_Management} alt="Asset Management" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='intro pb-5'>
                    <div className='__custom_container'>
                        <div className='row'>

                            <div className='col-lg-6'>
                                <div className="intro-right">
                                    <img src={__img_Rewards} alt="Asset Rewards" />
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className="intro-left">
                                    <h2>Earn Rewards, Effortlessly</h2>
                                    <p>Unlock the potential of your assets with Vista Finance's unique rewards system. By participating in various DeFi protocols, you can earn lucrative rewards, staking incentives, and even yield farming gains. Watch your assets grow organically as you engage with the DeFi ecosystem like never before.</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className='activity-sec'>
                    <div className='__custom_container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6'>
                                <div className='activ-card'>
                                    <div className='__icon'>
                                        <Security />
                                    </div>
                                    <h5> Your Security is Our Priority</h5>
                                    <p> We understand the paramount importance of security in the world of decentralized finance. That's why we employ cutting-edge encryption, multi-factor authentication, and robust security protocols to safeguard your assets. Rest easy, knowing that Vista Finance is your fortress against potential threats.</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div className='activ-card'>
                                    <div className='__icon'>
                                        <SupportAgent />
                                    </div>
                                    <h5> 24/7 Customer Support</h5>
                                    <p> Have a question or need assistance? Our dedicated customer support team is available around the clock to provide you with the assistance you need. We're here to guide you through your DeFi journey and address any concerns you may have.</p>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6'>
                                <div className='activ-card'>
                                    <div className='__icon'>
                                        <Diversity3 />
                                    </div>
                                    <h5> Join the Future of Finance</h5>
                                    <p> Vista Finance invites you to embark on a journey into the exciting and dynamic world of decentralized finance. Take control of your financial future, explore new opportunities, and experience the freedom of DeFi. Download Vista Finance today and become a part of the decentralized banking revolution.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='intro intro-second pt-5'>
                    <div className='__custom_container'>
                        <div className='row'>

                            <div className='col-lg-6'>
                                <div className="intro-left">
                                    <h2>Banking on Blockchain</h2>
                                    <p>Built on a secure and transparent blockchain infrastructure, Vista Finance ensures that your financial activities are tamper-proof and verifiable. Your transactions are executed with the highest level of security and transparency, keeping your assets safe from potential threats.</p>
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className="intro-right">
                                    <img src={__img_Blockchain} alt="Banking on Blockchain" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='intro '>
                    <div className='__custom_container'>
                        <div className='row'>

                            <div className='col-lg-6'>
                                <div className="intro-right">
                                    <img src={__img_Decentralized} alt="Decentralized Governance" />
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className="intro-left">
                                    <h2>Decentralized Governance</h2>
                                    <p>We believe in the power of community-driven decision-making. With Vista Finance, you have a voice in the platform's evolution. Participate in governance proposals, vote on critical changes, and shape the future of our decentralized banking app.</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className='intro intro-second pb-5'>
                    <div className='__custom_container'>
                        <div className='row'>

                            <div className='col-lg-6'>
                                <div className="intro-left">
                                    <h2>One Wallet, Many Possibilities</h2>
                                    <p>Vista Finance is more than just a wallet; it's your all-in-one solution for DeFi. Access lending and borrowing protocols, liquidity pools, decentralized exchanges, and much more – all from a single, secure platform. Say goodbye to the need for multiple apps and interfaces; Vista Finance brings everything you need under one roof.</p>
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className="intro-right">
                                    <img src={__img_one_wallet} alt="One Wallet" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='__Awaits'>
                    <div className='__custom_container'>
                        <div className='__img'>
                            <CardGiftcard/>
                        </div>
                        <h3>Your Financial Freedom Awaits</h3>
                        <p>With Vista Finance, your assets are in your hands, your rewards are within reach, and your financial future is yours to define. Embrace the future of banking – join Vista Finance and experience decentralized financial freedom like never before. Welcome to the future of finance, where you are in control.</p>


                    </div>
                </div>
            </div>
        </>
    );
}

export default Project;
