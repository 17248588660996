import React from 'react';
import './Assets/Scss/Style.scss';
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import Index from './Public/HomePage/Index';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import BlockchainData from './Public/BlockchainData';
import About from './Public/Pages/About';
import Team from './Public/Pages/Team';
import Cryptobanking from './Public/Pages/Cryptobanking';
import OurServices from './Public/Pages/OurServices';
import PrivacyPolicy from './Public/Pages/PrivacyPolicy';
import ContactUs from './Public/HomePage/ContactUs';
import RoadMap from './Public/HomePage/RoadMap';
import Project from './Public/Pages/Project';


function App() {

  window.scrollTo(0, document.body.scrollTop);



  // Public page.....
  function PublicLayout() {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    );
  }


  return (
    <>

      <Router>
        <Routes>

          {/* All Public pages___ */}
          <Route element={<PublicLayout />}>
            <Route path='*' element={<Index />} />
            <Route path='/intro' element={<BlockchainData />} />
            <Route path='/about' element={<About />} />
            <Route path='/team' element={<Team />} />
            <Route path='/crypto-banking' element={<Cryptobanking />} />
            <Route path='/our-services' element={<OurServices />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/roadmap' element={<RoadMap />} />
            <Route path='/project' element={<Project />} />
          </Route>
        </Routes>
      </Router>


    </>
  );
}

export default App;
