import site_logo from '../Assets/Icon/Logo.png';
import site_shortlogo from '../Assets/Icon/shortLogo.png';
export const logo = site_logo;
export const shortLogo = site_shortlogo;
export const title = 'Vista Finance';
export const ShortTitle = 'Vista';
export const emailAddress = 'info@vistafinance.io';
export const websiteName = 'vistafinance.io';
export const titleDescription = 'Vista Force';

