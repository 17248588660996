import React, { useState } from 'react';
import __Img_Team from '../../Assets/Image/__teamBanner.png';
import __Img_Team_1 from '../../Assets/Image/team-1.jpeg';
import __Img_Team_2 from '../../Assets/Image/team-2.jpeg';
import __Img_Team_3 from '../../Assets/Image/team-3.jpeg';
import { Col, Row } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';


const Team = () => {

    const [itemName, setItemName] = useState(false);
    const [itemName1, setItemName1] = useState(false);
    const [itemName2, setItemName2] = useState(false);


    return (
        <>
            <div className='team__'>
                <div className='team__banner' >
                    <div className="team-data">
                        <Fade bottom>
                            <div className='__img'>
                                <img src={__Img_Team} alt="img" />
                            </div>
                            <h3>Meet Our Team</h3>
                            <p>Our Dynamic and Diverse Team</p>
                        </Fade>
                        <div>
                            {/* <a href='#ourView'> Our Vision  <OpenInNew /> </a> */}
                        </div>
                    </div>
                </div>


                <div className='__sec_team'>
                    <div className='__custom_container'>
                        <Row>
                            <Col sm={12}>
                                <div className='__sec_header'>
                                    <h6> Team</h6>
                                    <h1>Meet our team</h1>
                                </div>
                            </Col>

                            <Col lg={4} md={6}>
                                <Fade left>
                                    <div className='__sec-team'>
                                        <div className='__imgTeam'>
                                            <img src={__Img_Team_1} alt='vision-img' />
                                        </div>

                                        <div className='__teamdata'>
                                            <div className='_imgName'>
                                                <h4>DR. PETER PUTZ</h4>
                                                <p>CEO, Vista Finance</p>
                                            </div>

                                            <p className={"__dis" + `${itemName ? " -full" : " -half"}`} >
                                                Dr. Peter Putz is a management scientist and internationally
                                                experienced manager of R&D software projects. Born in
                                                Austria, he spent the last 15 years in California. He was a
                                                senior scientist at the NASA Ames Research Center where
                                                he managed 15+ software projects in the Collaborative and
                                                Assistant Systems Area. Before joining NASA, Peter was a
                                                research staff member at the Palo Alto Research Center
                                                (PARC). In 2015, Peter and his family moved to Austria and
                                                made their home in Linz

                                                <button type='button' className={"btn -btnsc " + `${itemName ? "-less" : "-more"}`} onClick={() => { setItemName(!itemName) }}>  {itemName ? "Less" : "More"} </button>
                                            </p>
                                        </div>
                                    </div>
                                </Fade>
                            </Col>


                            <Col lg={4} md={6}>
                                <Fade bottom>
                                    <div className='__sec-team'>
                                        <div className='__imgTeam'>
                                            <img src={__Img_Team_2} alt='vision-img' />
                                        </div>

                                        <div className='__teamdata'>
                                            <div className='_imgName'>
                                                <h4>MR. COOPER EMMONS</h4>
                                                <p>CMO, Vista Finance</p>
                                            </div>

                                            <p className={"__dis" + `${itemName1 ? " -full" : " -half"}`} >
                                                Cooper Emmons, CMO at Vista Finance, merges
                                                quantitative economics and finance knowledge from
                                                the University of Miami and the London School of
                                                Economics. Former Severn School Varsity Captain,
                                                Investment Club President. Leading dynamic, datadriven marketing, enhancing Vista Finance's brand,
                                                and engaging clients with analytical insight and
                                                creative finesse.

                                                <button type='button' className={"btn -btnsc " + `${itemName1 ? "-less" : "-more"}`} onClick={() => { setItemName1(!itemName1) }}>  {itemName1 ? "Less" : "More"} </button>
                                            </p>
                                        </div>
                                    </div>
                                </Fade>
                            </Col>


                            <Col lg={4} md={6}>
                                <Fade right>
                                    <div className='__sec-team'>
                                        <div className='__imgTeam'>
                                            <img src={__Img_Team_3} alt='vision-img' />
                                        </div>

                                        <div className='__teamdata'>
                                            <div className='_imgName'>
                                                <h4>MR. LOO JIADI</h4>
                                                <p>MR. LOO JIADI</p>
                                            </div>

                                            <p className={"__dis" + `${itemName2 ? " -full" : " -half"}`} >
                                                Mr. Jiadi Xu is a research scientist at
                                                Kennedy Krieger Institute, as well as
                                                an Associate Professor of banking at
                                                the Johns Hopkins University School of
                                                Tech at the Johns Hopkins University
                                                School of technologies.

                                                <button type='button' className={"btn -btnsc " + `${itemName2 ? "-less" : "-more"}`} onClick={() => { setItemName2(!itemName2) }}>  {itemName2 ? "Less" : "More"} </button>
                                            </p>
                                        </div>
                                    </div>
                                </Fade>
                            </Col>


                        </Row>

                    </div>
                </div>



            </div>
        </>
    );
}

export default Team;
