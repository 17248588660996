import React, { useState } from 'react';
import __Img_crypto from '../../Assets/Image/__crypto.png';
import __Img_cryptoAssts from '../../Assets/Image/__crypto_asstrs.png';
import __Img_vision from '../../Assets/Image/action-img.png';
import __img__slider_1 from '../../Assets/Image/p2p__1.jpeg';
import { AddCard, Addchart, Calculate, CreditScore, CurrencyExchange, DoneAll, MiscellaneousServices, QuestionAnswer, SupervisedUserCircle, SupportAgent, Wallet } from '@mui/icons-material';
import { Col, Row } from 'react-bootstrap';
import { title } from '../../config/Config';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Fade from 'react-reveal/Fade';


const OurServices = () => {




    const [activeButton, setActiveButton] = useState('wallet');
    const [activeButton1, setActiveButton1] = useState('Interest');

    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
    };

    const LoanHead = (buttonType) => {
        setActiveButton1(buttonType);
    };


    return (
        <div className='crypto___' >
            <div className='crypto__banner' id='header-banner'>
                <div className="crypto-data">
                    <Fade bottom>
                        <div className='__img'>
                            <img src={__Img_crypto} alt="img" />
                        </div>
                        {/* <h6>About Us</h6> */}
                        <h3>Crypto Banking and Decentralized <br /> Finance</h3>
                        <p>The Revolution In Digital Money Is Now Moving Into Banking, As Cryptocurrency Starts To Reshape The Way People Borrow And Save</p>

                        <div>
                            <a href='#ourView'>    <MiscellaneousServices className='ms-0' />  <span className='d-inline-block ms-1'>Decentralized Services</span></a>
                        </div>
                    </Fade>
                </div>
            </div>


            <div className='__sec_about' >
                <div className='__custom_container' id="ourView">
                    <Row>

                        <Col sm={12}>
                            <div className='__sec_header mb-5' >
                                <h6> Decentralized Services </h6>
                                <h1> {activeButton === 'wallet' && (`Decentralized ${title} Wallet`)} {activeButton === 'exchange' && (`Decentralized ${title} Exchange`)} </h1>
                                <div className='__btns'>
                                    <button type='button' className={`btn ${activeButton === 'wallet' ? 'active' : ''}`} onClick={() => handleButtonClick('wallet')} > Decentralized Wallet </button>
                                    <button type='button' className={`btn ${activeButton === 'exchange' ? 'active' : ''}`} onClick={() => handleButtonClick('exchange')}> Decentralized exchange </button>
                                </div>
                            </div>
                        </Col>


                        <Col md={6}>
                            <Fade left>
                                {activeButton === 'wallet' && (
                                    <div className='__sec-card'>
                                        <h2> <Wallet /> wallet</h2>
                                        <h5>Decentralized Vista Wallet is more than just a digital wallet; it is a gateway to financial freedom. By prioritizing security, privacy, usability, and community involvement, it sets a new standard for what users should expect from their digital financial tools. Embrace the future of finance with Decentralized Vista Wallet and take control of your digital assets today.</h5>
                                        <ul>
                                            <li> <DoneAll />  Secured private keys</li>
                                            <li> <DoneAll /> Generate unique addresses</li>
                                            <li> <DoneAll /> Initiate and sign transactions </li>
                                            <li> <DoneAll /> Track balances </li>
                                            <li> <DoneAll /> Synchronize with a blockchain network </li>
                                            <li> <DoneAll />Incorporate security features </li>
                                            <li> <DoneAll />Support multiple cryptocurrencies </li>
                                        </ul>
                                    </div>
                                )}
                            </Fade>
                            <Fade left>
                                {activeButton === 'exchange' && (
                                    <div className='__sec-card'>
                                        <h2> <CurrencyExchange /> exchange</h2>
                                        <h5>Decentralized Vista exchange and take control of your digital assets today. </h5>
                                        <ul>
                                            <li> <DoneAll />  Peer-to-Peer Trading</li>
                                            <li> <DoneAll /> Decentralized Control</li>
                                            <li> <DoneAll /> Non-Custodial Trading </li>
                                            <li> <DoneAll /> Liquidity Pool </li>
                                            <li> <DoneAll /> Security and Privacy </li>
                                            <li> <DoneAll />Reduced Fees </li>
                                        </ul>
                                    </div>
                                )}
                            </Fade>



                        </Col>

                        <Col md={6}>
                            <div className='__sec-img'>
                                <Fade right>
                                    <img src={__Img_vision} alt='vision-img' />
                                </Fade>
                            </div>
                        </Col>


                    </Row>

                </div>
            </div>


            <div className='__swap'>
                <Row>
                    <Col sm={12}>
                        <div className='__sec_header mb-5' >
                            <h6> Swap / Converter </h6>
                            <h1> Crypto Swap and Converter</h1>
                        </div>
                    </Col>

                    <Col xl={2} lg={3} md={4} sm={6}>
                        <Fade left>
                            <div className='__swapCard'>
                                <div className='__icon-swap'>
                                    <QuestionAnswer />
                                </div>

                                <h4>Crypto currency Selection</h4>
                            </div>
                        </Fade>
                    </Col>

                    <Col xl={2} lg={3} md={4} sm={6}>
                        <Fade left>
                            <div className='__swapCard'>
                                <div className='__icon-swap'>
                                    <SupportAgent />
                                </div>

                                <h4>Fiat Currency Support</h4>
                            </div>
                        </Fade>
                    </Col>

                    <Col xl={2} lg={3} md={4} sm={6}>
                        <Fade left>
                            <div className='__swapCard'>
                                <div className='__icon-swap'>
                                    <CurrencyExchange />
                                </div>

                                <h4>Real-Time Exchange Rates</h4>
                            </div>
                        </Fade>
                    </Col>

                    <Col xl={2} lg={3} md={4} sm={6}>
                        <Fade right>
                            <div className='__swapCard'>
                                <div className='__icon-swap'>
                                    <Calculate />
                                </div>

                                <h4>Conversion Calculations</h4>
                            </div>
                        </Fade>
                    </Col>

                    <Col xl={2} lg={3} md={4} sm={6}>
                        <Fade right>
                            <div className='__swapCard'>
                                <div className='__icon-swap'>
                                    <SupervisedUserCircle />
                                </div>

                                <h4>User-Friendly Interface</h4>
                            </div>
                        </Fade>
                    </Col>

                    <Col xl={2} lg={3} md={4} sm={6}>
                        <Fade right>
                            <div className='__swapCard'>
                                <div className='__icon-swap'>
                                    <Addchart />
                                </div>

                                <h4>Historical Data and Charts</h4>
                            </div>
                        </Fade>
                    </Col>

                </Row>
            </div>

            <div className='__sec_about'>
                <div className='__custom_container'>
                    <Row>

                        <Col sm={12}>
                            <div className='__sec_header mb-5' >
                                <h6> Interest / Loan Services </h6>
                                <h1> {activeButton1 === 'Interest' && ("Interest on Crypto Assets")} {activeButton1 === 'Loan' && ("Loan on Crypto Assets")} </h1>
                                <div className='__btns'>
                                    <button type='button' className={`btn ${activeButton1 === 'Interest' ? 'active' : ''}`} onClick={() => LoanHead('Interest')} > Interest on Assets  </button>
                                    <button type='button' className={`btn ${activeButton1 === 'Loan' ? 'active' : ''}`} onClick={() => LoanHead('Loan')}> Loan on Assets </button>
                                </div>
                            </div>
                        </Col>


                        <Col md={6}>
                            <Fade left>
                                {activeButton1 === 'Interest' && (
                                    <div className='__sec-card'>
                                        <h2> <AddCard /> Interest</h2>
                                        <h5>Crypto assets have piqued widespread interest due to their volatility and potential gains. Blockchain technology underpins their decentralized nature, fostering transparency and innovation. However, regulatory uncertainties pose risks amid growing mainstream adoption.</h5>
                                        <ul className='mt-5'>
                                            <li> <DoneAll />  Interest Rates</li>
                                            <li> <DoneAll /> Lending Duration</li>
                                            <li> <DoneAll /> Risk Considerations </li>
                                            <li> <DoneAll /> Custody and Security </li>
                                            <li> <DoneAll /> Additional Benefits </li>
                                        </ul>
                                    </div>
                                )}
                            </Fade>
                            <Fade left>
                                {activeButton1 === 'Loan' && (
                                    <div className='__sec-card'>
                                        <h2> <CreditScore /> Loan</h2>
                                        <h5>Taking loans against crypto assets is gaining popularity. It leverages their value for liquidity without selling. However, risks involve market volatility and regulatory uncertainties.</h5>
                                        <ul className='mt-5'>
                                            <li> <DoneAll />  Crypto Collateral</li>
                                            <li> <DoneAll /> Loan-to-Value (LTV) Ratio</li>
                                            <li> <DoneAll /> Interest Rates and Terms </li>
                                            <li> <DoneAll /> Loan Repayment </li>
                                            <li> <DoneAll /> Custody and Security </li>
                                        </ul>
                                    </div>
                                )}
                            </Fade>
                        </Col>


                        <Col md={6}>
                            <div className='__sec-img'>
                                <Fade right>
                                    <img src={__Img_cryptoAssts} alt='Interest' />
                                </Fade>
                            </div>
                        </Col>




                    </Row>

                </div>
            </div>

            <div className='__buySell'>
                <div className='__custom_container'>
                    <Fade bottom>
                        <div className='__buyCard'>
                            {/* <div className='__sec_header mb-5' >
                            <h1> Buy / Sell</h1>
                        </div> */}
                            <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <div className='__cardslider'>

                                        <div className='__slider__img'>
                                            <img src={__img__slider_1} alt='img' />
                                        </div>

                                        <div className='__slider__txt'>
                                            <h4>Buy/Sell on flat <br /> currency</h4>
                                            <p> Account Registration and Verification</p>
                                            <p>  Deposit Fiat Currency</p>
                                            <p> Place a Buy or Sell Order </p>
                                            <p>Transaction Fees  </p>
                                            <p>Withdrawal or Deposit to Wallet  </p>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className='__cardslider'>

                                        <div className='__slider__img'>
                                            <img src={__img__slider_1} alt='img' />
                                        </div>

                                        <div className='__slider__txt'>
                                            <h4>Buy/Sell in <br /> P2P/B2P</h4>
                                            <p> Account creation</p>
                                            <p>  Buyer and seller Listings</p>
                                            <p> Payment and Fund Escrow </p>
                                            <p>Legal and regulatory Compliance  </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    );
}

export default OurServices;
