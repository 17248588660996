import React, { useState } from 'react';
import { title } from '../../config/Config';
import { OpenInNew } from '@mui/icons-material';
import { Col, Row } from 'react-bootstrap';
import __Img_vision from '../../Assets/Image/vision-1.jpeg';
import __Img_Team_1 from '../../Assets/Image/team-1.jpeg';
import __Img_Team_2 from '../../Assets/Image/team-2.jpeg';
import __Img_Team_3 from '../../Assets/Image/team-3.jpeg';
import __img__document from '../../Assets/Image/__document.png';
import __IMg_crypto from '../../Assets/Image/__crypto.png';
import Fade from 'react-reveal/Fade';

const About = () => {


  const [itemName, setItemName] = useState(false);
  const [itemName1, setItemName1] = useState(false);
  const [itemName2, setItemName2] = useState(false);



  return (
    <div className='about__'>
      <div className='ab-banner' id='header-banner'>
        <div className="ab-banner-data">
          <div className='__img'>
            <img src={__IMg_crypto} alt="img" />
          </div>
          {/* <h6>About Us</h6> */}
          <Fade bottom>
            <h3>{title} is one such crypto platform</h3>
            <p>which is the best platform to secure the
              transactions between the two parties to prevent any kind of problem between
              the merchant and consumer parties which are private management sectors in
              which blockchain technology and are based on smart contracts. We bring
              trade, brokers, traders, and investors together in a decentralized, open, and
              fair network to make them more modern and global. This backstage smart
              contract technology provides an automated and completely transparent
              method of investing and a profit- sharing system by offering investment
              attractiveness to successful traders around the world.</p>
            <div>
              <a href='#ourView'> Our Vision  <OpenInNew /> </a>
            </div>
          </Fade>
        </div>
      </div>

      <div className='__sec_about' id="ourView">
        <div className='__custom_container'>
          <Row >
            <Col sm={12}>
              <div className='__sec_header' >
                <h6> crypto finance</h6>
                <p>At {title}, we are at the forefront of the ever-evolving world
                  of crypto finance. As a leading company in the industry, we are
                  dedicated to providing innovative financial solutions that empower
                  individuals and businesses to leverage the full potential of
                  cryptocurrencies and blockchain technology.</p>
              </div>
            </Col>

            <Col md={6}>
              <div className='__sec-img'>
                <Fade left>
                  <img src={__Img_vision} alt='vision-img' />
                </Fade>
              </div>
            </Col>

            <Col md={6}>
              <div className='__sec-card'>
                <Fade right>
                  <h4>Our Vision</h4>
                  <p>We envision a future where cryptocurrencies are seamlessly integrated into the global
                    financial ecosystem, offering individuals and businesses unprecedented opportunities for
                    financial growth, security, and independence. We strive to be a catalyst for this
                    transformation, driving innovation and empowering our clients to navigate the crypto
                    landscape with confidence. </p>
                  <h4>Our mission</h4>
                  <p>Our mission is to democratize access to crypto finance by offering user-friendly
                    platforms, cutting-edge tools, and comprehensive educational resources. We are
                    committed to enabling our clients to navigate the complexities of the crypto market,
                    make informed investment decisions, and harness the power of decentralized
                    finance (DeFi) to build wealth and achieve financial freedom.</p>
                </Fade>
              </div>
            </Col>


          </Row>

        </div>
      </div>

      <div className='__sec_team'>
        <div className='__custom_container'>
          <Row>
            <Col sm={12}>
              <div className='__sec_header'>
                <h6> Team</h6>
                <h1>Meet our team</h1>
              </div>
            </Col>

            <Col lg={4} md={6}>
              <Fade left>
                <div className='__sec-team'>
                  <div className='__imgTeam'>
                    <img src={__Img_Team_1} alt='vision-img' />
                  </div>

                  <div className='__teamdata'>
                    <div className='_imgName'>
                      <h4>DR. PETER PUTZ</h4>
                      <p>CEO, Vista Finance</p>
                    </div>

                    <p className={"__dis" + `${itemName ? " -full" : " -half"}`} >
                      Dr. Peter Putz is a management scientist and internationally
                      experienced manager of R&D software projects. Born in
                      Austria, he spent the last 15 years in California. He was a
                      senior scientist at the NASA Ames Research Center where
                      he managed 15+ software projects in the Collaborative and
                      Assistant Systems Area. Before joining NASA, Peter was a
                      research staff member at the Palo Alto Research Center
                      (PARC). In 2015, Peter and his family moved to Austria and
                      made their home in Linz

                      <button type='button' className={"btn -btnsc " + `${itemName ? "-less" : "-more"}`} onClick={() => { setItemName(!itemName) }}>  {itemName ? "Less" : "More"} </button>
                    </p>
                  </div>
                </div>
              </Fade>
            </Col>


            <Col lg={4} md={6}>
              <Fade bottom>
                <div className='__sec-team'>
                  <div className='__imgTeam'>
                    <img src={__Img_Team_2} alt='vision-img' />
                  </div>

                  <div className='__teamdata'>
                    <div className='_imgName'>
                      <h4>MR. COOPER EMMONS</h4>
                      <p>CMO, Vista Finance</p>
                    </div>

                    <p className={"__dis" + `${itemName1 ? " -full" : " -half"}`} >
                      Cooper Emmons, CMO at Vista Finance, merges
                      quantitative economics and finance knowledge from
                      the University of Miami and the London School of
                      Economics. Former Severn School Varsity Captain,
                      Investment Club President. Leading dynamic, datadriven marketing, enhancing Vista Finance's brand,
                      and engaging clients with analytical insight and
                      creative finesse.

                      <button type='button' className={"btn -btnsc " + `${itemName1 ? "-less" : "-more"}`} onClick={() => { setItemName1(!itemName1) }}>  {itemName1 ? "Less" : "More"} </button>
                    </p>
                  </div>
                </div>
              </Fade>
            </Col>


            <Col lg={4} md={6}>
              <Fade right>
                <div className='__sec-team'>
                  <div className='__imgTeam'>
                    <img src={__Img_Team_3} alt='vision-img' />
                  </div>

                  <div className='__teamdata'>
                    <div className='_imgName'>
                      <h4>MR. LOO JIADI</h4>
                      <p>MR. LOO JIADI</p>
                    </div>

                    <p className={"__dis" + `${itemName2 ? " -full" : " -half"}`} >
                      Mr. Jiadi Xu is a research scientist at
                      Kennedy Krieger Institute, as well as
                      an Associate Professor of banking at
                      the Johns Hopkins University School of
                      Tech at the Johns Hopkins University
                      School of technologies.

                      <button type='button' className={"btn -btnsc " + `${itemName2 ? "-less" : "-more"}`} onClick={() => { setItemName2(!itemName2) }}>  {itemName2 ? "Less" : "More"} </button>
                    </p>
                  </div>
                </div>
              </Fade>
            </Col>


          </Row>

        </div>
      </div>


      <div className='services__a'>
        <Fade bottom>
          <div className='sec___custom_container'>
            <Row>
              <Col sm={12}>
                <div className='__sec_header'>
                  <p> services</p>
                  <h1>Our Banking services</h1>
                </div>
              </Col>

              <Col className='col-6'>
                <div className='serviceCard'>
                  <p>01</p>
                  <h3>Decentralized wallet</h3>
                </div>
              </Col>

              <Col className='col-6'>
                <div className='serviceCard'>
                  <p>02</p>
                  <h3>Swap & converter</h3>
                </div>
              </Col>

              <Col className='col-6'>
                <div className='serviceCard'>
                  <p>03</p>
                  <h3>Asset on interest</h3>
                </div>
              </Col>

              <Col className='col-6'>
                <div className='serviceCard'>
                  <p>04</p>
                  <h3>Loan on asset</h3>
                </div>
              </Col>

              <Col className='col-6'>
                <div className='serviceCard'>
                  <p>05</p>
                  <h3>Buy/Sell on fiat currency</h3>
                </div>
              </Col>

              <Col className='col-6'>
                <div className='serviceCard'>
                  <p>06</p>
                  <h3>Buy/sell in P2P</h3>
                </div>
              </Col>

              <Col className='col-6'>
                <div className='serviceCard'>
                  <p>07</p>
                  <h3>Financial services</h3>
                </div>
              </Col>

              <Col className='col-6'>
                <div className='serviceCard'>
                  <p>08</p>
                  <h3>Decentralized exchange</h3>
                </div>
              </Col>

            </Row>
          </div>
        </Fade>
      </div>

      {/* <div className='__document'>
        <Fade bottom>
          <div className='__custom_container'>
            <Row>
              <Col sm={12}>
                <div className='__sec_header'>
                  <h6> Document</h6>
                  <h1>Document legalisation</h1>
                </div>
              </Col>

              <Col sm="12">
                <div className='__docuentcard'>
                  <img src={__img__document} alt='uhns' />
                </div>
              </Col>
            </Row>
          </div>
        </Fade>
      </div> */}
    </div>
  );
}

export default About;
